export const colors = {
  primary: '#01763D',
  primary20: '#E2EFE9',
  primary40: '#C6E0D3',
  primary60: '#71B293',
  primary80: '#01763D',
  primary100: '#025C30',

  secondary: '#8FC440',
  secondary20: '#F2F8E9',
  secondary40: '#E6F1D4',
  secondary60: '#C0DE94',
  secondary80: '#8FC440',
  secondary100: '#6D982C',

  error: '#FF3B46',
  success: '#1DB46C',
  info: '#4292D7',
  warning: '#FDB614',

  white: '#FFFFFF',
  gray: '#F9F9F9',
  gray00: '#F5F8FA',
  gray05: '#E5E9ED',
  gray10: '#D5DAE0',
  gray20: '#C6CCD3',
  gray40: '#A7AFB9',
  gray60: '#697586',
  gray80: '#464E59',
  gray100: '#171A1D',
};
