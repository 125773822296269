import React, {useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {SubtitlePageViewHeader} from 'admin/components';
import {Button, Icon, Loading, PageViewHeader, PaginationLocal, Table, Tag} from 'components-lib';
import {ProgramsMasterInstrumentsSortBy, SidebarFilters} from './components';
import {PageLayoutTwoCol, PageLayoutWithFixedAreas} from 'layout';
import {IBindBaseEntity, IProgramMasterInstrumentBase} from 'models';
import {pages} from 'paths';
import {useUserId} from 'user';
import {cloneDeep, getLocationOrigin, toNumber} from 'utils';
import {useProgram} from '../hooks';
import {useNavigate} from 'hooks';
import {useProgramMasterInstruments} from './hooks';

export function ProgramMasterInstrumentsView() {
  const {push} = useNavigate();
  const {programId} = useParams<{programId: string}>();
  const {userId, userError} = useUserId();
  const locationOrigin = getLocationOrigin();

  const {program, fetchProgram} = useProgram(toNumber(programId));
  const {
    loading,
    instruments,
    filter,
    filterEntities,
    totalCount,
    perPage,
    currentPage,
    fetchInstruments,
    setFilter,
    setCurrentPage,
    setPerPage,
  } = useProgramMasterInstruments(toNumber(programId));

  useEffect(() => {
    fetchProgram(true);
  }, [fetchProgram]);

  useEffect(() => {
    fetchInstruments();
  }, [fetchInstruments]);

  const rows = useMemo(() => {
    const clickHandler = (instrumentId: number) => {
      if (!userError) {
        push(`${pages.survey.takerLink}/${userId}/${programId}/${instrumentId}`, {
          prevPath: window.location.pathname,
        });
      }
    };
    return instruments.result.map((id) => {
      const instrument: IProgramMasterInstrumentBase = instruments.entities.byId[id];

      const dropdownItems = [
        {
          text: 'View',
          isCopyLink: false,
          clickHandler: () =>
            push(`${pages.adminPortal.instruments.detailsRoot}/${instrument.id}`, {
              prevPath: window.location.pathname,
            }),
        },
        {
          text: 'Share',
          isCopyLink: true,
          copyText: `${locationOrigin}${pages.survey.takerLink}/${programId}/${instrument.id}`,
        },
      ];
      return {
        code: instrument.id,
        mtIcon: <Icon.MasterTemplateIcon />,
        name: instrument.name,
        audience: instrument.audience ? instrument.audience.displayText : '',
        type: instrument.type ? instrument.type.displayText : '',
        phase: instrument.phase ? instrument.phase.displayText : '',
        status: instrument.status === 'Live' ? 'Published' : instrument.status, // TODO enum
        viewBtn: <Button.Dropdown items={dropdownItems} />,
        survey: (
          <Button.Secondary size="small" clickHandler={() => clickHandler(instrument.id)}>
            Survey
          </Button.Secondary>
        ),
      };
    });
  }, [instruments.entities.byId, instruments.result, locationOrigin, programId, push, userError, userId]);

  const headerSubheading = useMemo(
    () => program && <SubtitlePageViewHeader> / {program?.name}</SubtitlePageViewHeader>,
    [program]
  );

  const headerSortOrder = useMemo(() => <ProgramsMasterInstrumentsSortBy />, []);

  const filters = useMemo(() => {
    const chips: any[] = [];

    const deleteHandler = (key: string, item: number | string) => {
      const updated = cloneDeep(filter);
      const idx = filter[key].indexOf(item);

      if (idx > -1) {
        updated[key].splice(idx, 1);
        setFilter(updated);
      }
    };

    if (!!filter.name) {
      chips.push(
        <Tag
          withFilterChip
          key={filter.name}
          label={filter.name}
          deleteHandler={() => setFilter({...filter, name: ''})}
        />
      );
    }

    if (!!filter.audiences) {
      filter.audiences.forEach((id) => {
        const option: IBindBaseEntity = filterEntities.audiences[id];
        option &&
          chips.push(<Tag withFilterChip label={option.name} deleteHandler={() => deleteHandler('audiences', id)} />);
      });
    }

    if (!!filter.phases) {
      filter.phases.forEach((id) => {
        const option: IBindBaseEntity = filterEntities.phases[id];
        option &&
          chips.push(<Tag withFilterChip label={option.name} deleteHandler={() => deleteHandler('phases', id)} />);
      });
    }

    return chips;
  }, [filter, filterEntities.audiences, filterEntities.phases, setFilter]);

  const header = useMemo(() => {
    return (
      <PageViewHeader
        heading="Programs"
        subHeading={headerSubheading}
        sort={headerSortOrder}
        filters={filters}
        withBackButton
        backButtonClickHandler={() => push(pages.adminPortal.programs.root)}
      />
    );
  }, [headerSubheading, filters, push, headerSortOrder]);

  const footer = useMemo(() => {
    return (
      <PaginationLocal
        onPageChange={setCurrentPage}
        onPerPageChange={setPerPage}
        totalCount={totalCount}
        perPage={perPage}
        currentPage={currentPage}
        disabled={loading}
      />
    );
  }, [currentPage, perPage, setCurrentPage, setPerPage, totalCount, loading]);

  const sidebar = useMemo(() => {
    return <SidebarFilters />;
  }, []);

  const content = useMemo(() => {
    return (
      <PageLayoutWithFixedAreas withSecondaryNavigation={false} header={header} footer={footer}>
        {loading ? <Loading /> : !!rows.length ? <Table rows={rows} columns={columns} /> : 'No results found'}
      </PageLayoutWithFixedAreas>
    );
  }, [footer, header, loading, rows]);

  return <PageLayoutTwoCol withSecondaryNavigation={false} sidebar={sidebar} content={content} />;
}

const columns: any[] = [
  {id: 'mtIcon', label: '', className: 'insrt-icon-col'},
  {id: 'name', label: 'Instrument'},
  {id: 'audience', label: 'Audience'},
  {id: 'type', label: 'Type'},
  {id: 'phase', label: 'Phase'},
  {id: 'status', label: 'Status'},
  {id: 'viewBtn', label: '', align: 'center', className: 'btn-col'},
];
