import {Button} from 'components-lib';
import {withRole} from 'permissions';
import {AdminRolesInstruments, AdminRolesWithLocalAreaInstruments, AllRolesExceptStudent} from '../enums';

export const ButtonPrimaryWithAdminPermissions = withRole(AdminRolesInstruments, '')(Button.Primary);
export const ButtonSecondaryWithAdminPermissions = withRole(AdminRolesInstruments, '')(Button.Secondary);

export const ButtonPrimaryWithAdminAndLocalAreaPermissions = withRole(
  AdminRolesWithLocalAreaInstruments,
  ''
)(Button.Primary);
export const ButtonSecondaryWithAdminAndLocalAreaPermissions = withRole(
  AdminRolesWithLocalAreaInstruments,
  ''
)(Button.Secondary);
export const ButtonSecondaryWithAllRolesExceptStudentPermissions = withRole(
  AllRolesExceptStudent,
  ''
)(Button.Secondary);
