import {ExportTypesEnum, ExportTypesNamesEnum, ReportTypesEnum, ReportTypesNamesEnum} from '../../../../enums';

export const getReportTypes = () => [
  // remove deployPastTestFlag fields when reports are tested.
  {
    name: ReportTypesNamesEnum.ImpactSummary,
    type: ReportTypesEnum.ImpactSummary,
    deployPastTestFlag: true,
  },
  {
    name: ReportTypesNamesEnum.StudentGradebook,
    type: ReportTypesEnum.StudentGradebookReport,
    deployPastTestFlag: true,
  },
  {
    name: ReportTypesNamesEnum.AchievementSummary,
    type: ReportTypesEnum.AchievementSummary,
    deployPastTestFlag: false,
  },
  {
    name: ReportTypesNamesEnum.StudentAchievementSummary,
    type: ReportTypesEnum.StudentAchievementSummary,
    deployPastTestFlag: false,
  },
];

export const getExportTypes = () => [
  {
    name: ExportTypesNamesEnum.RawDataFile,
    type: ExportTypesEnum.DataFile,
  },
  {
    name: ExportTypesNamesEnum.RawDataFileDetailed,
    type: ExportTypesEnum.DataFileDetailed,
  },
];
