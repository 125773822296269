import {config} from 'config';
import {get, post, put} from './http-client';
import {ISurvey} from 'survey-react';
import {ApiPathsEnum} from 'enums';
import {
  IBindInstrumentsAllPage,
  IBindStringResponse,
  ICreateQuestionPayload,
  IGetActiveSurveyApiResponse,
  IGetAreaProgramClassExportResponse,
  IGetGeneralInstrumentExportResponse,
  IGetItemsApiResponse,
  IGetPageOfInstrumentsPayload,
  IInstrumentDetails,
  IInstrumentDetailsAndParticipantSummary,
  IMetaDataDto,
  IQuestionBase,
  ISaveGeneralSurveyApiResponse,
  ISaveGeneralSurveyPayload,
  IUpdateDraftInstrumentPayload,
  IUpdateInstrumentApiResponse,
  IUpdateInstrumentInfoPayload,
  IUpdateInstrumentMetaDataPayload,
  IUpdateInstrumentPayload,
  TGetQuestionsApiResponse,
} from 'models';
import {IFetchPoolItemsMappedFilterPayload} from 'models/library/instruments/IFetchPoolItemsPayload';
import {getApiMultipartFormDataHeadersConfig, isNumber, returnQueryStringWithParams} from 'utils';
import {IHttpResponse} from './models';
import {axiosApiInstance} from './axios-interceptors';

const {jaEeApi: apiConfig} = config;

const pageSizeDefault = 50;
const pageNumberDefault = 1;
const itemsPageSizeDefault = 10;

export const instrumentsApi = {
  async getInstrumentById(id: number): Promise<IHttpResponse<IInstrumentDetails>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/GetTemplateAndItemsById?templateId=${id}`;
    return await get<IInstrumentDetails>(url);
  },

  async createNewTemplate(metaData: IMetaDataDto, isGeneral = false): Promise<IHttpResponse<number>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/CreateTemplate?isGeneral=${isGeneral}`;
    return await post<number>(url, metaData);
  },

  async postCloneTemplate(
    templateId: number,
    metaData: IMetaDataDto,
    isGeneral = false
  ): Promise<IHttpResponse<number>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/CloneTemplate?templateId=${templateId}&isGeneral=${isGeneral}`;
    return await post<number>(url, metaData);
  },

  async postDeleteTemplate(templateId: number): Promise<IHttpResponse<IBindStringResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/DeleteTemplate?templateId=${templateId}`;
    return await post<IBindStringResponse>(url, false);
  },

  async postDuplicateTemplate(templateId: number, programSessionId: number): Promise<IHttpResponse<number>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/DuplicateTemplate`;
    return await post<number>(url, {templateId: templateId, programSessionId: programSessionId});
  },

  async getInstrumentItemsAndParticipantsById(
    id: number
  ): Promise<IHttpResponse<IInstrumentDetailsAndParticipantSummary>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/GetTemplateItemsAndParticipantsByTemplateId?templateId=${id}`;
    return await get<IInstrumentDetailsAndParticipantSummary>(url);
  },

  async updateInstrumentMetaData(payload: IUpdateInstrumentMetaDataPayload): Promise<IHttpResponse<null>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/AddMetaData?templateId=${payload.metaData.id}`;
    return await post<null>(url, payload.metaData.data);
  },

  async updateInstrumentInfo(payload: IUpdateInstrumentInfoPayload): Promise<IHttpResponse<null>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/EditInstrument`;
    return await post<null>(url, payload);
  },

  async updateInstrument(payload: IUpdateInstrumentPayload): Promise<IHttpResponse<IUpdateInstrumentApiResponse>> {
    const config = getApiMultipartFormDataHeadersConfig();
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/UpdateInstrument`;
    return axiosApiInstance.post(url, payload, config);
  },

  async unPublishSessionInstrument(id: number): Promise<IHttpResponse<undefined>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/UnPublishSessionTemplate?templateId=${id}`;
    const body = {templateId: id};
    return await put<undefined>(url, body);
  },

  async getMatrixRelatedItems(
    defaultAnswerStackId: number,
    pageNumber = pageNumberDefault,
    builderFilter: IFetchPoolItemsMappedFilterPayload,
    searchText = ''
  ): Promise<IHttpResponse<IGetItemsApiResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${
      ApiPathsEnum.ITEM
    }/GetItemsByDefaultAnswerStack?pageNumber=${pageNumber}&pageSize=${pageSizeDefault}&defaultAnswerStackId=${
      isNumber(defaultAnswerStackId) ? defaultAnswerStackId : -1
    }`;
    const body = {
      pageNumber: `${pageNumber}`,
      pageSize: `${pageSizeDefault}`,
      text: searchText,
      ...builderFilter,
    };
    return await post<IGetItemsApiResponse>(url, body);
  },

  // TODO response type
  async updateDraftInstrumentById({instrumentId, items}: IUpdateDraftInstrumentPayload): Promise<IHttpResponse<any>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.INSTRUMENT}/UpdateDraftInstrumentScheme?templateId=${instrumentId}`;
    return await put<any>(url, items);
  },

  async getItems(
    pageNumber = pageNumberDefault,
    builderFilter: IFetchPoolItemsMappedFilterPayload
  ): Promise<IHttpResponse<IGetItemsApiResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/GetItems?pageNumber=${pageNumber}&pageSize=${itemsPageSizeDefault}`;
    const body = {
      pageNumber: `${pageNumber}`,
      pageSize: `${pageSizeDefault}`,
      itemTypesFilter: [],
      isPublished: true,
      areaId: null,
      ...builderFilter,
    };
    return await post<IGetItemsApiResponse>(url, body);
  },

  async getQuestions(isApproved = false): Promise<IHttpResponse<TGetQuestionsApiResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.QUESTION}/GetQuestions?isApproved=${isApproved}`;
    return await get<TGetQuestionsApiResponse>(url);
  },

  async createQuestion(payload: ICreateQuestionPayload): Promise<IHttpResponse<IQuestionBase>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/CreateQuestion`;
    return await post<IQuestionBase>(url, payload);
  },

  async getActiveSurvey(
    userId: number,
    programId: number,
    masterTemplateId: number
  ): Promise<IHttpResponse<IGetActiveSurveyApiResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/GetActiveUserSurvey?userId=${userId}&programId=${programId}&masterTemplateId=${masterTemplateId}`;
    return await get<IGetActiveSurveyApiResponse>(url);
  },

  async getGeneralSurvey(instrumentId: number): Promise<IHttpResponse<ISurvey>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.GENERAL_TEMPLATE}/GetGeneralSurvey?id=${instrumentId}`;
    return await post<ISurvey>(url, null);
  },

  async saveSurvey(
    userId: number,
    templateId: number,
    results: {[id: number]: string}
  ): Promise<IHttpResponse<IGetActiveSurveyApiResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/ProcessAssignmentResults?userId=${userId}&templateId=${templateId}`;
    return await post<IGetActiveSurveyApiResponse>(url, {assignmentResults: results, userIpAddress: null});
  },

  async saveGeneralSurvey({
    instrumentId,
    results,
    timeSpent,
    areaId,
  }: ISaveGeneralSurveyPayload): Promise<IHttpResponse<ISaveGeneralSurveyApiResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.GENERAL_TEMPLATE}/ProcessAssignmentResults?templateId=${instrumentId}`;
    return await post<ISaveGeneralSurveyApiResponse>(url, {
      assignmentResults: results,
      userIpAddress: null,
      timeSpent,
      areaId,
    });
  },

  async getPageOfInstruments({
    filter,
    pagination,
    sortOptions,
  }: IGetPageOfInstrumentsPayload): Promise<IHttpResponse<IBindInstrumentsAllPage>> {
    const queryString = returnQueryStringWithParams(
      `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/GetMasterTemplatesPaged`,
      {
        ...pagination,
        ...sortOptions,
      }
    );

    return await post<IBindInstrumentsAllPage>(queryString, filter);
  },

  async getPossiblePretestItems(templateId: number): Promise<IHttpResponse<any>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/GetPossiblePretestItems?templateId=${templateId}`;
    return await get<any>(url);
  },

  async getGeneralInstrumentExport(url: string): Promise<IHttpResponse<IGetGeneralInstrumentExportResponse>> {
    return await get<IGetGeneralInstrumentExportResponse>(url);
  },

  async getAreaProgramClassExport(url: string): Promise<IHttpResponse<IGetAreaProgramClassExportResponse>> {
    return await get<IGetAreaProgramClassExportResponse>(url);
  },

  async getAreaProgramClassDetailedExport(url: string): Promise<IHttpResponse<IGetAreaProgramClassExportResponse>> {
    return await get<IGetAreaProgramClassExportResponse>(url);
  },

  async getInstrumentForPreview(id: number): Promise<IHttpResponse<IInstrumentDetails>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/GetTemplateForPreview?id=${id}`;
    return await get<IInstrumentDetails>(url);
  },

  async putPublishMasterTemplate(templateId: number): Promise<IHttpResponse<IBindStringResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/PublishMasterTemplate?templateId=${templateId}`;
    return await put<IBindStringResponse>(url, null);
  },
};
