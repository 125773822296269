import React, {useCallback} from 'react';
import {ISurvey, ReactSurveyModel, Survey} from 'survey-react';
import {useSurvey} from 'survey-taker';
import {cloneDeep} from 'utils';
import {isFunction} from 'utils';
import {TimeSpentTracker} from '../../utils';
import './survey.css';

const customCss = {
  question: {
    title: 'question-title',
    number: 'question-number',
  },
  page: {
    title: 'page-title',
    root: 'page-root',
  },
  checkbox: {
    label: 'checkbox-label',
    item: 'checkbox-item',
    controlLabel: 'checkbox-control-label',
  },
  radiogroup: {
    label: 'radio-label',
    item: 'radio-item',

    controlLabel: 'radio-control-label',
  },
  navigationButton: 'navigation-button',
  matrix: {
    root: 'table',
  },
  text: 'input-field',
};

const timeSpentTracker = new TimeSpentTracker();

interface ISurveyTakerProps {
  survey: ISurvey;
  isPreview?: boolean;
  isPreviewWithAnswers?: boolean;
  saveResultsClickHandler?: (results: {[id: number]: string}, timeSpent: number) => void;
}

export function SurveyTaker({
  survey,
  saveResultsClickHandler,
  isPreview = false,
  isPreviewWithAnswers = false,
}: ISurveyTakerProps) {
  !isPreview && timeSpentTracker.start();

  const {
    setDefaultAnswersForPreview,
    checkIfSurveyHasSinglePage,
    getSurveyPagesLength,
    appendMaxCharsToTextField,
  } = useSurvey();

  const clonedSurvey: ISurvey = cloneDeep(survey);
  const surveyLen = getSurveyPagesLength(clonedSurvey);
  const isSinglePage = checkIfSurveyHasSinglePage(surveyLen);

  isPreviewWithAnswers && setDefaultAnswersForPreview(clonedSurvey, isSinglePage);

  const completeHandler = useCallback(
    (results: ReactSurveyModel) => {
      isFunction(saveResultsClickHandler) &&
        saveResultsClickHandler(results.valuesHash, !isPreview && timeSpentTracker.end());
    },
    [isPreview, saveResultsClickHandler]
  );

  const onAfterRenderQuestion = useCallback(
    (survey, options) => {
      appendMaxCharsToTextField(options, clonedSurvey);
    },
    [appendMaxCharsToTextField, clonedSurvey]
  );

  return (
    <Survey
      onAfterRenderQuestion={onAfterRenderQuestion}
      json={clonedSurvey}
      onComplete={completeHandler}
      css={customCss}
    />
  );
}
