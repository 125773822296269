import React, {Fragment, useCallback, useEffect, useMemo} from 'react';
import {Prompt, useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {useBeforeunload} from 'react-beforeunload';
import {IHttpResponse} from 'api';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';
import {useAppDispatch, useNavigate} from 'hooks';
import {useInstrument} from 'admin/library/instruments/details/hooks';
import {useProgram, useProgramReports, useProgramReportsStoreActions} from '../hooks';
import {toastNotificationManager} from 'toast-notifications';
import {pages} from 'paths';
import {Button, Container, Loading, PageViewHeader, Row, Table, Text} from 'components-lib';
import {SidebarBase} from 'components-lib/sidebar/SidebarBase';
import {programsMessages, SubtitlePageViewHeader} from 'admin';
import {PageLayoutTwoCol, PageLayoutWithFixedAreas} from 'layout';
import {LevelReportingMenu, ViewExpansionPanel} from '../components';
import {AreaSelect} from '../components/AreaSelect';
import {IGetProgramReportResponse} from 'models';
import {ALL_AREAS_INDEX, createLinkForDownload, toNumber} from 'utils';
import {columns, generateReport, IProgramClassReportsQueryParams} from '..';
import {ExportTypesEnum, ReportExportFormatsEnum, ReportTypesEnum, ReportTypeTermsEnum} from '../enums';
import {reportMessages} from '../utils';
import {defaultProgramMenuOptionId} from '../store/programDetails.initialState';

type IProgramReportsQueryParams = IProgramClassReportsQueryParams;

export const ProgramReportsAndExportsView = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const {reportTypes, exportTypes, loading, setIsLoading, selectedAreaId, setSelectedAreaId} = useProgramReports();
  const {isProgramLevelAllOption, selectedItem, selectedId, clearProgramReportsState} = useProgramReportsStoreActions();
  const {programId} = useParams<{programId: string}>();
  const programIdParam = toNumber(programId);
  const {hasPermission} = usePermissions();
  const {push} = useNavigate();
  const {program, fetchProgram} = useProgram(programIdParam);
  const {getAreaProgramClassExport, getAreaProgramClassDetailedExport} = useInstrument();

  useBeforeunload(() => {
    if (loading) {
      return reportMessages.progress;
    }
  });

  const shouldDisableAchievementReports = !isProgramLevelAllOption;

  useEffect(() => {
    fetchProgram(true);
  }, [fetchProgram]);

  useEffect(() => {
    return () => {
      clearProgramReportsState();
    };
  }, [clearProgramReportsState]);

  const filteredReportTypes = useMemo(() => {
    if (isProgramLevelAllOption) {
      return reportTypes;
    }

    return reportTypes.filter(
      (item) => !item.name.toLowerCase().includes(ReportTypeTermsEnum.Achievement.toLowerCase())
    );
  }, [reportTypes, isProgramLevelAllOption]);

  const reportRows = useMemo(() => {
    const generateReportClickHandler = async (type: ReportTypesEnum, exportFormat: ReportExportFormatsEnum) => {
      setIsLoading(true);

      const params: IProgramReportsQueryParams = {
        programId: programIdParam,
        type,
        exportFormat,
      };

      if (selectedAreaId > ALL_AREAS_INDEX) {
        params.areaId = selectedAreaId;
      }

      if (selectedId > defaultProgramMenuOptionId) {
        params.matchedSurveyTypesId = selectedId;
      }

      try {
        const response: IHttpResponse<IGetProgramReportResponse> = await generateReport(type, params);

        if (response.ok) {
          const {mimeType, data, fileName} = response.parsedBody;
          createLinkForDownload(`data:${mimeType};base64,${data}`, fileName);
          dispatch(toastNotificationManager.createSuccessToastAction(programsMessages.reportSuccess));
        }
      } catch (err) {
        dispatch(toastNotificationManager.createErrorToastAction(err.statusText || programsMessages.reportError));
      } finally {
        setIsLoading(false);
      }
    };

    return filteredReportTypes.map(({name, type}) => ({
      name,
      viewBtn: (
        <Fragment>
          <Button.Secondary
            size="small"
            clickHandler={() => generateReportClickHandler(type, ReportExportFormatsEnum.PDF)}
            disabled={loading}
          >
            PDF Report
          </Button.Secondary>
          <Button.Secondary
            size="small"
            clickHandler={() => generateReportClickHandler(type, ReportExportFormatsEnum.CSV)}
            disabled={loading}
          >
            Excel Report
          </Button.Secondary>
        </Fragment>
      ),
    }));
  }, [dispatch, loading, programIdParam, selectedAreaId, filteredReportTypes, setIsLoading, selectedId]);

  // Raw Data Export

  const getRawDataExportForParticularArea = useCallback(
    () => getAreaProgramClassExport(programIdParam, null, selectedAreaId),
    [getAreaProgramClassExport, programIdParam, selectedAreaId]
  );

  const getDetailedDataExportForParticularArea = useCallback(
    () => getAreaProgramClassDetailedExport(programIdParam, null, selectedAreaId),
    [getAreaProgramClassDetailedExport, programIdParam, selectedAreaId]
  );

  const getRawExportForParticularArea = useCallback(
    (isRawDataExport: boolean) => {
      if (isRawDataExport) {
        return getRawDataExportForParticularArea();
      } else {
        return getDetailedDataExportForParticularArea();
      }
    },
    [getDetailedDataExportForParticularArea, getRawDataExportForParticularArea]
  );

  // Raw Data Export (Detailed)

  const getRawDataExportForAllAreas = useCallback(() => getAreaProgramClassExport(programIdParam, null, null), [
    getAreaProgramClassExport,
    programIdParam,
  ]);

  const getDetailedDataExportForAllAreas = useCallback(
    () => getAreaProgramClassDetailedExport(programIdParam, null, null),
    [getAreaProgramClassDetailedExport, programIdParam]
  );

  const getRawExportForAllAreas = useCallback(
    (isRawDataExport: boolean) => {
      if (isRawDataExport) {
        return getRawDataExportForAllAreas();
      } else {
        return getDetailedDataExportForAllAreas();
      }
    },
    [getRawDataExportForAllAreas, getDetailedDataExportForAllAreas]
  );

  const exportRows = useMemo(() => {
    const generateExportClickHandler = async (type: ExportTypesEnum) => {
      const isRawDataExport = type === ExportTypesEnum.DataFile;

      setIsLoading(true);

      if (selectedAreaId > ALL_AREAS_INDEX) {
        getRawExportForParticularArea(isRawDataExport)
          .then(() => setIsLoading(false))
          .finally(() => setIsLoading(false));
      } else {
        getRawExportForAllAreas(isRawDataExport)
          .then(() => setIsLoading(false))
          .finally(() => setIsLoading(false));
      }
    };

    if (!hasPermission(USAAndSuperAdminRoles)) {
      exportTypes.pop();
    }

    return exportTypes.map(({name, type}) => ({
      name,
      viewBtn: (
        <Fragment>
          <Button.Secondary
            size="small"
            clickHandler={() => generateExportClickHandler(type)}
            disabled={loading || shouldDisableAchievementReports}
          >
            CSV Export
          </Button.Secondary>
        </Fragment>
      ),
    }));
  }, [
    loading,
    exportTypes,
    shouldDisableAchievementReports,
    setIsLoading,
    selectedAreaId,
    getRawExportForAllAreas,
    getRawExportForParticularArea,
  ]);

  const headerSubheading = useMemo(
    () => program && <SubtitlePageViewHeader> / {program?.name}</SubtitlePageViewHeader>,
    [program]
  );

  const header = useMemo(() => {
    return (
      <PageViewHeader
        heading="Programs"
        subHeading={headerSubheading}
        withBackButton
        backButtonClickHandler={() => push(`${pages.adminPortal.programs.root}`)}
      />
    );
  }, [headerSubheading, push]);

  const sidebar = useMemo(() => {
    const shouldRenderAreaFilter = hasPermission(USAAndSuperAdminRoles);

    return (
      <SidebarBase>
        <ViewExpansionPanel disabled={loading} />
        {!!shouldRenderAreaFilter && (
          <AreaSelect selectedArea={selectedAreaId} onSelectArea={setSelectedAreaId} disabled={loading} />
        )}
      </SidebarBase>
    );
  }, [hasPermission, loading, selectedAreaId, setSelectedAreaId]);

  const content = useMemo(() => {
    return (
      <PageLayoutWithFixedAreas header={header} headerSize="small" withSecondaryNavigation={false}>
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <Container disableGutters classes={{root: classes.exports}}>
              <Row justify="flex-start">
                <Text.Heading as="h3">Exports</Text.Heading>
              </Row>
              <Container disableGutters classes={{root: classes.exportsTable}}>
                <Text.Heading as="h4">Aggregated program-level data</Text.Heading>
                <Table columns={columns} rows={exportRows} />
              </Container>
            </Container>
            <Row justify="space-between">
              <Text.Heading as="h3">Reports</Text.Heading>
              <LevelReportingMenu />
            </Row>
            {selectedItem && <Text.Heading as="h4">{selectedItem?.displayText}</Text.Heading>}
            <Table columns={columns} rows={reportRows} />
          </Fragment>
        )}
        <Prompt when={loading} message={reportMessages.progress} />
      </PageLayoutWithFixedAreas>
    );
  }, [classes.exports, classes.exportsTable, header, reportRows, exportRows, loading, selectedItem]);

  return <PageLayoutTwoCol withSecondaryNavigation={false} sidebar={sidebar} content={content} />;
};

const useStyles = makeStyles((theme) => ({
  exports: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    marginBottom: theme.spacing(3),
  },
  exportsTable: {
    paddingBottom: theme.spacing(2),
  },
}));
