import React, {Fragment, useCallback, useEffect, useMemo} from 'react';
import {Prompt, useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {useBeforeunload} from 'react-beforeunload';
import {IHttpResponse} from 'api';
import {pages} from 'paths';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';
import {programsMessages, SubtitlePageViewHeader} from 'admin';
import {Button, Container, Loading, PageViewHeader, Row, Table, Text} from 'components-lib';
import {SidebarBase} from 'components-lib/sidebar/SidebarBase';
import {PageLayoutTwoCol, PageLayoutWithFixedAreas} from 'layout';
import {ViewExpansionPanel} from '../components';
import {LevelReportingMenu} from 'admin/programs/details/components';
import {toastNotificationManager} from 'toast-notifications';
import {useProgramClass} from '../hooks';
import {useProgram, useProgramReports, useProgramReportsStoreActions} from 'admin/programs/details/hooks';
import {useAppDispatch, useNavigate} from 'hooks';
import {useInstrument} from 'admin/library/instruments/details/hooks';
import {IProgramClassReportsQueryParams} from 'admin/programs/details';
import {IGetProgramReportResponse, IProgramClassReportParams} from 'models';
import {createLinkForDownload, toNumber} from 'utils';
import {
  ExportTypesEnum,
  ProgramDetailsViewTypeEnum,
  ReportExportFormatsEnum,
  ReportTypesEnum,
  ReportTypeTermsEnum,
} from 'admin/programs/details/enums';
import {columns, generateReport} from './utils';
import {reportMessages} from 'admin/programs/details/utils';
import {defaultProgramMenuOptionId} from 'admin/programs/details/store';

export const ProgramClassReportsAndExportsView = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const {programId, classId} = useParams<IProgramClassReportParams>();
  const programIdParam = toNumber(programId);
  const classIdParam = toNumber(classId);
  const {push} = useNavigate();
  const {program, fetchProgram} = useProgram(programIdParam);
  const {currentClass, fetchClass} = useProgramClass(classIdParam);
  const {getAreaProgramClassExport, getAreaProgramClassDetailedExport} = useInstrument();
  const {reportTypes, exportTypes, loading, setIsLoading} = useProgramReports();
  const {isProgramLevelAllOption, selectedItem, clearProgramReportsState, selectedId} = useProgramReportsStoreActions();
  const {hasPermission} = usePermissions();

  useBeforeunload(() => {
    if (loading) {
      return reportMessages.progress;
    }
  });

  const shouldDisableAchievementReports = !isProgramLevelAllOption;

  useEffect(() => {
    fetchProgram(true);
  }, [fetchProgram]);

  useEffect(() => {
    fetchClass(true);
  }, [fetchClass]);

  useEffect(() => {
    return () => {
      clearProgramReportsState();
    };
  }, [clearProgramReportsState]);

  const filteredReportTypes = useMemo(() => {
    if (isProgramLevelAllOption) {
      return reportTypes;
    }

    return reportTypes.filter(
      (item) => !item.name.toLowerCase().includes(ReportTypeTermsEnum.Achievement.toLowerCase())
    );
  }, [reportTypes, isProgramLevelAllOption]);

  // Raw Data Export

  const getRawDataExport = useCallback(() => getAreaProgramClassExport(programIdParam, classIdParam, null), [
    classIdParam,
    programIdParam,
    getAreaProgramClassExport,
  ]);

  // Raw Data Export (Detailed)

  const getDetailedDataExport = useCallback(
    () => getAreaProgramClassDetailedExport(programIdParam, classIdParam, null),
    [classIdParam, programIdParam, getAreaProgramClassDetailedExport]
  );

  const getRawExport = useCallback(
    (isRawDataExport: boolean) => {
      if (isRawDataExport) {
        return getRawDataExport();
      } else {
        return getDetailedDataExport();
      }
    },
    [getRawDataExport, getDetailedDataExport]
  );

  const reportRows = useMemo(() => {
    const generateReportClickHandler = async (type: ReportTypesEnum, exportFormat: ReportExportFormatsEnum) => {
      setIsLoading(true);

      const params: IProgramClassReportsQueryParams = {
        programId: programIdParam,
        programSessionId: classIdParam,
        areaId: null,
        type,
        exportFormat,
      };

      if (selectedId > defaultProgramMenuOptionId) {
        params.matchedSurveyTypesId = selectedId;
      }

      try {
        const response: IHttpResponse<IGetProgramReportResponse> = await generateReport(type, params);

        if (response.ok) {
          const {mimeType, data, fileName} = response.parsedBody;
          createLinkForDownload(`data:${mimeType};base64,${data}`, fileName);
          dispatch(toastNotificationManager.createSuccessToastAction(programsMessages.reportSuccess));
        }
      } catch (err) {
        dispatch(toastNotificationManager.createErrorToastAction(err.statusText || programsMessages.reportError));
      } finally {
        setIsLoading(false);
      }
    };

    return filteredReportTypes.map(({name, type}) => ({
      name,
      viewBtn: (
        <>
          <Button.Secondary
            size="small"
            clickHandler={() => generateReportClickHandler(type, ReportExportFormatsEnum.PDF)}
            disabled={loading}
          >
            PDF Report
          </Button.Secondary>
          <Button.Secondary
            size="small"
            clickHandler={() => generateReportClickHandler(type, ReportExportFormatsEnum.CSV)}
            disabled={loading}
          >
            Excel Report
          </Button.Secondary>
        </>
      ),
    }));
  }, [classIdParam, programIdParam, dispatch, loading, filteredReportTypes, setIsLoading, selectedId]);

  const exportRows = useMemo(() => {
    const generateExportClickHandler = async (type: ExportTypesEnum) => {
      const isRawDataExport = type === ExportTypesEnum.DataFile;

      setIsLoading(true);

      return getRawExport(isRawDataExport)
        .then(() => setIsLoading(false))
        .finally(() => setIsLoading(false));
    };

    if (!hasPermission(USAAndSuperAdminRoles)) {
      exportTypes.pop();
    }

    return exportTypes.map(({name, type}) => ({
      name,
      viewBtn: (
        <Fragment>
          <Button.Secondary
            size="small"
            clickHandler={() => generateExportClickHandler(type)}
            disabled={loading || shouldDisableAchievementReports}
          >
            CSV Export
          </Button.Secondary>
        </Fragment>
      ),
    }));
  }, [loading, exportTypes, setIsLoading, shouldDisableAchievementReports, getRawExport]);

  const headerSubheading = useMemo(
    () =>
      program && (
        <SubtitlePageViewHeader>
          {' '}
          / {program?.name} / {currentClass?.name}
        </SubtitlePageViewHeader>
      ),
    [currentClass, program]
  );

  const header = useMemo(() => {
    return (
      <PageViewHeader
        heading="Programs"
        subHeading={headerSubheading}
        withBackButton
        backButtonClickHandler={() => push(`${pages.adminPortal.programs.detailsLink}/${programId}`)}
      />
    );
  }, [headerSubheading, programId, push]);

  const content = useMemo(() => {
    return (
      <PageLayoutWithFixedAreas header={header} headerSize="small" withSecondaryNavigation={false}>
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <Container disableGutters classes={{root: classes.exports}}>
              <Row justify="flex-start">
                <Text.Heading as="h3">Exports</Text.Heading>
              </Row>
              <Container disableGutters classes={{root: classes.exportsTable}}>
                <Text.Heading as="h4">Aggregated program-level data</Text.Heading>
                <Table columns={columns} rows={exportRows} />
              </Container>
            </Container>
            <Row justify="space-between">
              <Text.Heading as="h3">Reports</Text.Heading>
              <LevelReportingMenu programViewType={ProgramDetailsViewTypeEnum.Class} />
            </Row>
            {selectedItem && <Text.Heading as="h4">{selectedItem?.displayText}</Text.Heading>}
            <Table columns={columns} rows={reportRows} />
          </Fragment>
        )}
        <Prompt when={loading} message={reportMessages.progress} />
      </PageLayoutWithFixedAreas>
    );
  }, [header, reportRows, loading, classes.exports, classes.exportsTable, exportRows, selectedItem]);

  const sidebar = useMemo(() => {
    return (
      <SidebarBase>
        <ViewExpansionPanel disabled={loading} />
      </SidebarBase>
    );
  }, [loading]);

  return <PageLayoutTwoCol withSecondaryNavigation={false} sidebar={sidebar} content={content} />;
};

const useStyles = makeStyles((theme) => ({
  exports: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    marginBottom: theme.spacing(3),
  },
  exportsTable: {
    paddingBottom: theme.spacing(2),
  },
}));
