import React, {useRef} from 'react';
import {ISurvey} from 'survey-react';
import {useReactToPrint} from 'react-to-print';
import {makeStyles} from '@material-ui/core';
import {Button, Column, Container, Loading, Row, Text} from 'components-lib';
import {layoutConfig} from 'layout';
import {FooterLogo, PrintSurveyTaker, SurveyTaker, ViewError, ViewSuccess} from '..';

const defaultErrorMessage = 'Something Went Wrong.';

interface ISurveyTakerContentProps {
  loading: boolean;
  error: any;
  surveyTaken: boolean;
  survey: ISurvey;
  isPreview?: boolean;
  isPreviewWithAnswers?: boolean;
  saveResultsHandler?: (results: {[id: number]: string}, timeSpent: number) => void;
}

export function SurveyTakerContent({
  loading,
  error,
  surveyTaken,
  survey,
  isPreview = false,
  isPreviewWithAnswers = false,
  saveResultsHandler,
}: ISurveyTakerContentProps) {
  const classes = useStyles();
  const printRef = useRef(null);
  const isPreviewMode = isPreview || isPreviewWithAnswers;

  const reactToPrintContent = React.useCallback(() => {
    return printRef.current;
  }, [printRef]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
  });

  if (loading) {
    return <Loading fullHeight />;
  }

  if (error) {
    return <ViewError text={error ?? defaultErrorMessage} />;
  }

  if (surveyTaken) {
    return <ViewSuccess />;
  }

  return (
    <Container classes={{root: classes.root}}>
      <Row align="center">
        <Column sm={12} md={12} lg={9} xl={9}>
          {survey ? (
            <>
              <div className={classes.heading}>
                {isPreview && (
                  <Text.Heading as="h2" color="primary">
                    Instrument Preview
                  </Text.Heading>
                )}
                {isPreviewWithAnswers && (
                  <Text.Heading as="h2" color="primary">
                    Instrument Preview with Answers
                  </Text.Heading>
                )}
                {isPreviewMode && (
                  <Button.Primary size="large" clickHandler={handlePrint}>
                    Print
                  </Button.Primary>
                )}
              </div>

              <PrintSurveyTaker printRef={printRef}>
                <SurveyTaker
                  survey={survey}
                  saveResultsClickHandler={saveResultsHandler}
                  isPreviewWithAnswers={isPreviewWithAnswers}
                />
                <FooterLogo survey={survey} isPreview={isPreviewMode} />
              </PrintSurveyTaker>
            </>
          ) : (
            <Row align="center" justify="center" classes={{root: classes.loading}}>
              <Text.Paragraph>We are loading your survey...</Text.Paragraph>
            </Row>
          )}
        </Column>
      </Row>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: `calc(100vh - ${layoutConfig.scrollableAreaWithoutSecondaryNavigationSubtrahend}px)`,
  },
  loading: {
    marginTop: 200,
  },
  heading: {
    padding: `${theme.spacing(3)}px`,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
