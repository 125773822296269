import React, {FC, ReactNode, RefObject} from 'react';

interface IPrintSurveyTakerProps {
  children: ReactNode;
  printRef: RefObject<HTMLDivElement>;
}

export const PrintSurveyTaker: FC<IPrintSurveyTakerProps> = ({printRef, children}) => (
  <div ref={printRef}>{children}</div>
);
